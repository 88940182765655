import { Component } from "react";
import styled from "styled-components";

// Utils
import {
  Font,
  Color,
  rem,
  media,
  responsive,
  Opacity,
} from "../../utils/style";
import { graphql, StaticQuery } from "gatsby";

// Components
import Container from "../Container";
import TestimonialsCarousel from "./TestimonialsCarousel";
import Text from "../Text";

const TestimonialsWrapper = styled.section.attrs({
  "aria-labelledby": "testimonials-headline",
})`
  padding: 80px 0;
  z-index: 2;
  background: rgba(242, 241, 245, ${Opacity.light});

  .container {
    overflow: visible !important;
    padding: 0 20px;
  }

  ${media.tablet`
    padding: 80px 0;

    .container {
      padding: 0 20px;
    }
  `};

  ${responsive.md`
    padding: 120px 0 ${(p) => (p.paddingBottom ? p.paddingBottom : 40)}px;

    .container {
      padding: 0;
    }
  `}
`;

const TestimonialsContainer = styled(Container)``;

const TestimonialsColumn = styled.div.attrs({
  className: "d-flex flex-column w-100",
})``;

const Headline = styled.h2.attrs({
  id: "testimonials-headline",
})`
  ${Font.circular}
  color: ${Color.ritualBlue};
  font-size: ${rem(24)};
  line-height: ${rem(36)};
  letter-spacing: -0.4px;
  font-weight: 500;
  text-align: center !important;
  margin: 0 0 24px;
  ${responsive.sm`
  font-size: ${rem(30)};
`};
  ${responsive.md`
    font-size: ${rem(48)};
    line-height: ${rem(54)};
    letter-spacing: -1.4px;
    margin: 0 0 16px;
  `};
  ${responsive.lg`
    font-size: ${rem(40)};
  `};
`;

const Subhead = styled.p`
  ${Font.dutch}
  color: ${Color.ritualBlue};
  font-size: ${rem(16)};
  line-height: ${rem(26)};
  font-weight: normal;
  text-align: center;
  letter-spacing: 0;
  margin: 0;

  ${responsive.md`
    font-size: ${rem(18)};
    line-height: ${rem(28)};
  `};
`;

export const TestimonialsComponent = class Testimonials extends Component {
  render() {
    const { data, paddingBottom } = this.props;
    const testimonials = data.contentfulHomePage.testimonials;
    return (
      <TestimonialsWrapper paddingBottom={paddingBottom}>
        <TestimonialsContainer>
          <TestimonialsColumn>
            <Headline>
              <Text
                id="testimonials.headline"
                defaultMessage="Skeptics Speak"
              />
            </Headline>
            <Subhead>
              <Text
                id="testimonials.subhead"
                defaultMessage="With millions of bottles delivered, discover what Ritual users
              have to say."
              />
            </Subhead>
          </TestimonialsColumn>
          <TestimonialsCarousel slides={testimonials} />
        </TestimonialsContainer>
      </TestimonialsWrapper>
    );
  }
};

const componentQuery = graphql`
  query TestimonialsNewComponentQuery {
    contentfulHomePage {
      testimonials {
        image {
          file {
            url
          }
        }
        verticalImage {
          gatsbyImageData(
            layout: FIXED
            placeholder: DOMINANT_COLOR
            width: 375
            quality: 90
          )
        }
        name
        shortname
        title
        pullquote {
          pullquote
        }
        videoContent {
          sources {
            file {
              url
              contentType
            }
          }
          captions {
            description
            file {
              url
            }
          }
        }
      }
    }
  }
`;

export const TestimonialsComponentQuery = (props) => (
  <StaticQuery
    query={componentQuery}
    render={(data) => <TestimonialsComponent {...props} data={data} />}
  />
);

export default TestimonialsComponentQuery;
