import styled from "styled-components";
import { MagicSVGImage } from "../../MagicSVGImage";
import { BBBLogo, BCorpLogo } from "../../../utils/svg";

const LogoSpacer = styled.span`
  flex-shrink: 0;
  display: block;
  position: relative;
  overflow: hidden;
  margin: 0px 20px 0px 0px;
  padding: 0px;
  width: 28px;
  height: 40px;

  &:last-of-type {
    margin-right: 24px;
  }

  img {
    width: 28px;
    height: 40px;
  }
`;

const BBBLink = () => {
  return (
    <>
      <LogoSpacer aria-label="Certified B Corporation" role="img">
        <a
          href="https://www.bcorporation.net/en-us/find-a-b-corp/company/ritual/"
          target="_blank"
          rel="noreferrer"
          aria-label="Ritual on the Certified B Corporation Website"
        >
          <MagicSVGImage
            width={28}
            height={40}
            alt={"B Corporation Logo"}
            src={BCorpLogo}
          />
        </a>
      </LogoSpacer>
      <LogoSpacer aria-label="Better Business Bureau" role="img">
        <a
          target="_blank"
          href="https://www.bbb.org/us/ca/los-angeles/profile/vitamins-and-supplements/ritual-1216-911505#bbbseal"
          rel="noreferrer"
          aria-label="Ritual on the Better Business Bureau Website"
        >
          <MagicSVGImage
            width={28}
            height={40}
            alt={"Better Business Bureau Logo"}
            src={BBBLogo}
          />
        </a>
      </LogoSpacer>
    </>
  );
};

export default BBBLink;
