import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image";
import styled from "styled-components";
import GridColumn from "../grid/GridColumn";
import { documentToHtmlString } from "@contentful/rich-text-html-renderer";
import { responsive } from "../../utils/style";

const EditorialBlockLargeElement = styled.div`
  &.editorial-block-large {
    display: block;
    margin-top: var(--spacing-2);
  }

  .text-container {
    display: flex;
    flex-direction: column;
    color: var(--indigo-blue, #142b6f);
    gap: var(--spacing-1);
  }

  .text-container:not(.no-images) {
    margin-bottom: var(--spacing-1_5);

    h3,
    p {
      margin-bottom: var(--spacing-0, 0);
    }
  }

  h3 > i {
    font-family: var(--font-dutch) !important;

    p {
      margin-bottom: 0 !important;
    }
  }

  .image-container {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-1);
  }

  .footnote {
    color: var(--indigo-blue-60);
  }

  .ritual-image img.loaded {
    position: relative;
  }

  .body-container {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-0_5);

    p {
      margin-bottom: unset;
    }
  }

  @media (min-width: 750px /* Grid S Tablet */) {
    .text-container {
      flex-direction: row;
      justify-content: space-between;
    }

    .image-container {
      display: flex;
      flex-direction: row;
      gap: var(--spacing-1_5);
      padding: 0;
      margin: 0;
    }

    .image-base {
      height: auto;
      background-color: grey;
      padding: 0;
      margin: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
    }

    .image-base img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }

    .ritual-image img.loaded {
      position: absolute;
    }

    .image-primary {
      flex: 3 1 0;
      aspect-ratio: 466 / 280;
    }

    .image-primary.single-image {
      aspect-ratio: 3804 / 1430;
    }

    .image-secondary {
      flex-grow: 1;
      flex-shrink: 1;
      flex-basis: 0;
    }
  }
`;

const ImageContainer = styled.div`
  display: block;
  height: 100%;
  width: 100%;

  .gatsby-image-wrapper {
    height: 100%;
    width: 100%;
  }
`;

const EBLGridRow = styled.div`
  display: grid;
  grid-template-columns: repeat(var(--grid-columns-xs), 1fr);
  gap: var(--grid-gutter-xs, 16px);
  width: 100%;
  flex: 0 0 100%;

  h3, p {
    margin-bottom: unset;
  }

  ${responsive.sm`
    grid-template-columns: repeat(var(--grid-columns-s), 1fr);
    gap: var(--grid-gutter-s, 15px);
    row-gap: var(--grid-row-gap-xs, 48px);
  `}

  ${responsive.md`
    grid-template-columns: repeat(var(--grid-columns-m), 1fr);
    gap: var(--grid-gutter-m, 25px);
    row-gap: var(--grid-row-gap-m, 64px);
  `}
`

type Content = {
  headline: {
    raw: string;
  };
  description: {
    raw: string;
  };
  footnote: string;
  images: {
    description: string;
    gatsbyImageData: IGatsbyImageData;
  }[];
};

type Props = {
  item: {
    content: Content;
  };
};

type EditorialBlocksLargeProps = (props: Props) => JSX.Element | null;

const EditorialBlocksLarge: EditorialBlocksLargeProps = ({ item }) => {
  const { content } = item;
  const { headline, description, images, footnote } = content;

  const formattedHeadline =
    headline && documentToHtmlString(JSON.parse(headline.raw));

  const formattedDescription =
    description && documentToHtmlString(JSON.parse(description.raw));

  return (
    <EditorialBlockLargeElement className="editorial-block-large">
      <div className={`text-container ${!images?.length ? "no-images" : ""}`}>
        <EBLGridRow>
          <GridColumn xs={{ size: 6 }} s={{ size: 4 }} m={{ size: 4 }}>
            <h3
              className="typography-lead1"
              dangerouslySetInnerHTML={{ __html: formattedHeadline }}
            ></h3>
          </GridColumn>
          <GridColumn
            xs={{ size: 6, hide: true }}
            s={{ size: 6, hide: true }}
            m={{ size: 1 }}
          ></GridColumn>
          <GridColumn xs={{ size: 6 }} s={{ size: 8 }} m={{ size: 7 }}>
            <div className="body-container">
              <span
                className="typography-body1 font-circular"
                dangerouslySetInnerHTML={{ __html: formattedDescription }}
              ></span>
              {footnote && (
                <span className="footnote typography-caption">{footnote}</span>
              )}
            </div>
          </GridColumn>
        </EBLGridRow>
      </div>
      {images && (
        <div className="image-container">
          {images && images[0] && (
            <div
              className={`image-base image-primary ${
                images?.length < 2 ? "single-image" : ""
              }`}
            >
              <ImageContainer slot="editorial-image-primary">
                <GatsbyImage
                  image={images[0]?.gatsbyImageData as IGatsbyImageData}
                  alt={images[0]?.description}
                />
              </ImageContainer>
            </div>
          )}
          {images && images[1] && (
            <ImageContainer slot="editorial-image-secondary">
              <GatsbyImage
                image={images[1]?.gatsbyImageData as IGatsbyImageData}
                alt={images[1]?.description}
                style={{
                  height: "100%",
                  width: "100%",
                }}
              />
            </ImageContainer>
          )}
        </div>
      )}
    </EditorialBlockLargeElement>
  );
};

export default EditorialBlocksLarge;
