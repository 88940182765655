import styled from "styled-components";

import { IPostalAddress } from "../../utils/types/validateAddressTypes";

const Container = styled.div<{ $underlineAddress: boolean }>`
  color: var(--Solid-Primary-IndigoBlue-100, #142b6f);
  font-weight: 450;
  font-size: 14px;

  p {
    margin-bottom: 0;
    text-decoration: ${(props) =>
      props.$underlineAddress ? "underline" : "none"};
  }
`;

const SuggestedAddress = ({
  postalAddress,
  underlineAddress = false,
}: {
  postalAddress: IPostalAddress;
  underlineAddress?: boolean;
}) => {
  return (
    <Container $underlineAddress={underlineAddress}>
      <p className="typography-body3 font-circular">
        {postalAddress?.addressLines?.[0]}
        {postalAddress?.addressLines?.[1] && (
          <span>{` ${postalAddress.addressLines[1]}`}</span>
        )}
      </p>
      <p className="typography-body3 font-circular">
        {postalAddress?.locality}
        {postalAddress?.administrativeArea
          ? `, ${postalAddress.administrativeArea}`
          : ""}
        {` ${postalAddress?.postalCode}`}
      </p>
    </Container>
  );
};

export default SuggestedAddress;
