import styled, { keyframes } from "styled-components";
import { PlanTile, PlanTileBenefit } from "@ritual/essentials-for-react";
import intl from "../../../services/intl";
import { cadencePlanDetails } from "../../../utils/ritualData";
import classNames from "classnames";
import { Icons } from "../../../utils/react-svg";
import { responsive } from "../../../utils/style";
import useVariation from "../../../hooks/useVariation";
import { useSelector } from "react-redux";
import { bestEligiblePromotion } from "../../../store/promotion/selectors";
import { getPromotionDetails } from "../../../utils/promotion";
import usePromotionDiscount from "../../../hooks/usePromotionDiscount";

const CadenceButton = styled.button<{ selected: boolean }>`
  border: none;
  margin: var(--spacing-0_5) 0 0;
  cursor: pointer;
  width: 100%;

  &:hover {
    background-color: "#f0f0f0";
  }
`;

const FadeInBorder = keyframes`
  from {
    border-color: var(--indigo-blue-20);
  }
  to {
    border-color: var(--indigo-blue);
  }
`;

const FadeOutBorder = keyframes`
  from {
    border-color: var(--indigo-blue);
  }
  to {
    border-color: var(--indigo-blue-20);
  }
`;

const PlanTileContainer = styled(PlanTile)`
  overflow: hidden;
  border-color: var(--indigo-blue-20);
  animation: 300ms ease-in-out reverse forwards ${FadeInBorder};

  &.one-time {
    .plan-tile__header .title {
      margin-bottom: unset;
    }
  }

  &[is-selected="false"] {
    animation: 300ms ease-in-out ${FadeOutBorder};
  }

  &[is-selected="true"] {
    animation: 300ms ease-in-out ${FadeInBorder};

    .price-row .base-price {
      font-weight: 450 !important;
    }
  }

  .plan-tile__header {
    .title {
      margin-bottom: unset;

      div.label {
        color: unset;
        margin-bottom: unset;
      }
    }
  }

  plan-tile-benefit {
    text-align: left !important;
  }

  .plan-tile-benefit-row {
    align-items: flex-end;

    ${responsive.md`
      align-items: baseline;
    `}
  }

  .benefit-icon {
    svg {
      width: 16px;
      height: 16px;

      g g {
        fill: var(--indigo-blue-60);
      }
    }
  }

  &.hide-benefits {
    .plan-tile__benefits {
      display: none;
    }
  }

  ${responsive.md`
    .title .label .typography-label1 {
      font-size: 1.125rem !important;
    }
  `}
`;

type Props = {
  plan: any;
  selectedPlanId: string;
  defaultPlanId: string;
  handlePlanClick: (plan: any) => void;
  index: number;
  bestValue?: boolean;
  deliveryCadenceCopyFlag?: boolean;
};

const SelectorOption = ({
  plan,
  selectedPlanId,
  defaultPlanId,
  handlePlanClick,
  index,
  bestValue = false,
  deliveryCadenceCopyFlag,
}: Props) => {
  const {
    selectorHeader,
    amount,
    basePrice,
    quarterlyBasePrice,
    monthlyAmount,
    monthlyPercentSavings,
    cadenceClarity,
  } = cadencePlanDetails(plan, deliveryCadenceCopyFlag);

  const saleSelectorUpdatesFlag = useVariation("sale-selector-updates");
  const promotion = useSelector(bestEligiblePromotion);
  const promotionDiscount = usePromotionDiscount(promotion, plan.plan_id);
  const promotionDetails = getPromotionDetails(
    promotionDiscount,
    (plan?.amount || 0) / 100,
  );
  const saleSelectorUpdates =
    saleSelectorUpdatesFlag &&
    promotionDetails?.discountPrice !== Number(amount);

  const isSelected = selectedPlanId
    ? plan.plan_id === selectedPlanId
    : plan.plan_id === defaultPlanId;
  const isOneTimePlan = plan.billing_type === "one_time";

  const finalPrice = quarterlyBasePrice ? quarterlyBasePrice : basePrice;

  return (
    <CadenceButton
      key={index}
      selected={isSelected}
      onClick={() => handlePlanClick(plan)}
      aria-label={selectorHeader}
    >
      <PlanTileContainer
        className={!isSelected ? "hide-benefits" : ""}
        isSelected={isSelected}
        flagLabel={bestValue ? "Best Value" : undefined}
      >
        <span
          className={classNames("font-circular", {
            "typography-label1": isSelected,
            "typography-body1": !isSelected,
          })}
          slot="plan-tile-title"
        >
          {selectorHeader}
        </span>

        {amount && (
          // This is the final price
          <span slot="plan-tile-final-price">
            {saleSelectorUpdates && promotionDetails?.discountPrice
              ? intl.formatCurrency(promotionDetails.discountPrice, {
                  round: true,
                })
              : amount}
          </span>
        )}

        {!isOneTimePlan && (
          <>
            {basePrice && (
              // This is the strike-through price
              <span slot="plan-tile-base-price">
                {saleSelectorUpdates ? amount : finalPrice}
              </span>
            )}
            {monthlyAmount && !saleSelectorUpdates && (
              <span slot="plan-tile-monthly-price">{`${monthlyAmount}/month`}</span>
            )}
            {monthlyPercentSavings && !saleSelectorUpdates && (
              <span slot="plan-tile-savings">{`Subscribe and Save ${monthlyPercentSavings}`}</span>
            )}
            {monthlyPercentSavings && saleSelectorUpdates && (
              <span slot="plan-tile-savings">
                Save {promotionDetails.formattedValue} on your first order
              </span>
            )}
            <PlanTileBenefit slot="plan-tile-benefit">
              <span className="benefit-icon" slot="plan-tile-benefit-icon">
                <Icons.RecurringBenefit />
              </span>
              <span slot="plan-tile-benefit-text">{cadenceClarity}</span>
            </PlanTileBenefit>
            <PlanTileBenefit slot="plan-tile-benefit">
              <span className="benefit-icon" slot="plan-tile-benefit-icon">
                <Icons.TestedBenefit />
              </span>
              <span slot="plan-tile-benefit-text">
                {intl.t(
                  `delivery-cadence.benefits.${
                    plan.number_of_months === 1 ? "monthly" : "non-monthly"
                  }`,
                )}
              </span>
            </PlanTileBenefit>
          </>
        )}
      </PlanTileContainer>
    </CadenceButton>
  );
};

export default SelectorOption;
