import styled from "styled-components";
import GridColumn from "../grid/GridColumn";
import GridRow from "../grid/GridRow";
import intl from "../../services/intl";
import { cadencePlanDetails } from "../../utils/ritualData";
import metrics from "../../utils/metrics";
import { getProductSkuForPlanId } from "../../utils/planToProduct";
import { getPropertiesForProductSku } from "../../utils/tracking/helpers";
import SelectorOption from "./cadence/SelectorOption";
import useVariation from "../../hooks/useVariation";

const PlanSelectorContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: var(--spacing-1_5);

  h3.selector-header {
    margin-bottom: unset;
  }
`;

type Props = {
  cadencePlans: any[];
  selectedPlanId: string;
  setSelectedPlanId: (planId: string) => void;
};

type Plan = {
  plan_id: string;
  basePrice: number;
  amount: number;
};

const CadenceSelector = (props: Props) => {
  const { cadencePlans, selectedPlanId, setSelectedPlanId } = props;
  const deliveryCadenceCopyFlag = useVariation("delivery-cadence-copy", false);

  if (!cadencePlans) return null;

  const handlePlanClick = (plan: Plan) => {
    const { selectorHeader } = cadencePlanDetails(plan);
    const sku = getProductSkuForPlanId(plan.plan_id);

    metrics.track("Variant Clicked", {
      ...getPropertiesForProductSku(sku),
      price: plan.amount / 100,
      location: "Single Product PDP HERO",
      title: selectorHeader,
    });

    setSelectedPlanId(plan.plan_id);
  };

  const defaultPlanId =
    selectedPlanId ||
    cadencePlans.find(
      (plan: any) =>
        plan.interval_count === 30 && plan.billing_type === "recurring",
    ).plan_id;

  return (
    <PlanSelectorContainer className="test-id">
      <GridRow>
        <GridColumn xs={{ size: 6 }} s={{ size: 12 }} m={{ size: 12 }}>
          <h3 className="typography-body2 font-circular selector-header">
            {deliveryCadenceCopyFlag
              ? "Select Your Cadence"
              : intl.t("delivery-cadence.selector.header")}
          </h3>
          {cadencePlans.map((plan, index) => {
            return (
              <SelectorOption
                index={index}
                plan={plan}
                selectedPlanId={selectedPlanId}
                defaultPlanId={defaultPlanId}
                handlePlanClick={handlePlanClick}
                deliveryCadenceCopyFlag={deliveryCadenceCopyFlag}
              />
            );
          })}
        </GridColumn>
      </GridRow>
    </PlanSelectorContainer>
  );
};

export default CadenceSelector;
