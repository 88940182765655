import { readAuthenticationCookie } from "./currentUser";
import { removeCookie } from "./cookies";

export const CustomerScope = "customer";

export function hasScope(scope) {
  const currentScope = readAuthenticationCookie().scope;
  return !!currentScope && currentScope.indexOf(scope) > -1;
}

export function isAuthenticated() {
  return readAuthenticationCookie().access_token !== undefined;
}

export function logout(removeGuestId = true) {
  if (isAuthenticated()) {
    removeCookie("ritual_auth-session", {
      path: "/",
      domain: process.env.GATSBY_COOKIE_DOMAIN,
    });

    if (removeGuestId) {
      removeCookie("guest_id", {
        path: "/",
        domain: process.env.GATSBY_COOKIE_DOMAIN,
      });
    }
  }
}
