import { Component } from "react";
import styled from "styled-components";
import { responsive } from "../utils/style";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const InstagramImageLink = styled.a`
  width: calc(50vw - 30px) !important;
  height: calc(50vw - 30px) !important;
  margin-bottom: 20px;
  ${responsive.sm`
    width: calc(25% - 20px ) !important;
    height: calc(25% - 20px )!important;
    margin-bottom: 0;
  `}
`;

const ImageWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;

  .instagram-image {
    width: 100% !important;
    height: 100% !important;

    ${responsive.sm`
      width: 160px !important;
      height: 160px !important;
    `}

    ${responsive.md`
      width: 213px !important;
      height: 213px !important;
    `}

    ${responsive.lg`
      width: 270px !important;
      height: 270px !important;
    `}
  }
`;

export default class InstagramImage extends Component {
  render() {
    const { image } = this.props;

    return (
      <InstagramImageLink
        className="intagramImage"
        href="https://www.instagram.com/ritual/"
        target="_self"
        aria-label={image?.description}
        rel="noopener noreferrer"
      >
        <ImageWrapper>
          <GatsbyImage
            className="instagram-image"
            image={getImage(image)}
            loading="eager"
            alt={image.description}
            style={{
              userSelect: "none",
              userDrag: "none",
              pointerEvents: "none",
              touchCallout: "none",
            }}
          />
        </ImageWrapper>
      </InstagramImageLink>
    );
  }
}
