import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";

import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import BugsnagPerformance, {
  DefaultRoutingProvider,
} from "@bugsnag/browser-performance";
import * as Sentry from "@sentry/gatsby";

// Utils
import { analytics as analyticsService } from "./src/utils/analytics";
import { currentPageData } from "./src/utils/currentPageData";
import { polyfillPluralRules, preloadTranslations } from "./src/utils/intl";
import metrics from "./src/utils/metrics";

// Services
import launchDarkly from "./src/services/launchDarkly";
import castle from "./src/services/castle";

// Stripe
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

// Store
import { getStore } from "./src/store/createStore";
import { updateRoute } from "./src/store/route/actions";
import InitializeStore from "./src/components/global/InitializeStore";
import InitializeLocale from "./src/components/global/InitializeLocale";

// Animations
import { TweenMax } from "gsap";
import ScrollMagic from "scrollmagic";
import ScrollMagicGsapPlugin from "ScrollMagicGsapPlugin";

import { defineCustomElements } from "@ritual/essentials-for-react";

// For Bugsnag Performance
// This function helps group similar routes together
function resolveRoute(url) {
  switch (true) {
    case url.pathname.match(/\/products\/.*/i):
      return "/products/:product_id";
    case url.pathname.match(/\/articles\/categories\/.*/i):
      return "/articles/categories/:category_id";
    case url.pathname.match(/\/articles\/.*/i):
      return "/articles/:article_id";
    case url.pathname.match(/\/shop\/.*/i):
      return "/shop/:slug";
    default:
      return url.pathname;
  }
}

export const wrapRootElement = ({ element }) => {
  Bugsnag.start({
    apiKey: process.env.GATSBY_BUGSNAG_API_KEY,
    appVersion: process.env.GATSBY_APP_VERSION,
    plugins: [new BugsnagPluginReact()],
    releaseStage: process.env.GATSBY_BUGSNAG_RELEASE_STAGE,
    maxBreadcrumbs: 100,
    maxEvents: 20,
    onError: function (event) {
      if (window.FS && window.FS.getCurrentSessionURL) {
        event.addMetadata("fullstory", {
          url: window.FS.getCurrentSessionURL(true),
        });
      }
    },
  });

  Sentry.init({
    dsn: process.env.GATSBY_SENTRY_DSN,
    environment: process.env.GATSBY_ACTIVE_ENV || "development",
    release: process.env.GATSBY_APP_VERSION,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
      Sentry.launchDarklyIntegration(),
    ],
    // Tracing
    tracesSampleRate:
      parseFloat(process.env.GATSBY_SENTRY_TRACE_SAMPLE_RATE) || 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [
      "localhost",
      /^https:\/\/api\.ritual\.com\/api/,
      /^https:\/\/(?:.+\.)?ritual\.engineering\/api/,
    ],
    // Session Replay
    replaysSessionSampleRate:
      parseFloat(process.env.GATSBY_SENTRY_REPLAY_SESSION_SAMPLE_RATE) || 1.0,
    replaysOnErrorSampleRate:
      parseFloat(process.env.GATSBY_SENTRY_REPLAY_ERROR_SAMPLE_RATE) || 1.0,
  });

  var ErrorBoundary = Bugsnag.getPlugin("react").createErrorBoundary(React);

  // Stripe Elements
  const stripePromise = loadStripe(process.env.GATSBY_STRIPE_PUBLISHABLE_KEY);
  const elementsOptions = {
    mode: "payment",
    amount: 50, // TODO: Replace with cart total.
    currency: "usd", // TODO: Replace with cart currency.
    setupFutureUsage: "off_session",
    fonts: [
      {
        family: "CircularXX",
        src: "url(https://fonts.ritual.com/Circular/Book/css/fonts/CircularXXWeb-Book.woff2)",
      },
    ],
    // Customizable with appearance API.
    appearance: {
      theme: "stripe",
    },
  };

  // Instantiating store in `wrapRootElement` handler ensures:
  //  - there is fresh store for each SSR page
  //  - it will be called only once in browser, when React mounts
  const store = getStore();
  return (
    <ErrorBoundary>
      <InitializeStore store={store}>
        <Provider store={store}>
          <Elements stripe={stripePromise} options={elementsOptions}>
            {element}
          </Elements>
        </Provider>
      </InitializeStore>
    </ErrorBoundary>
  );
};

export const onClientEntry = async () => {
  defineCustomElements();
  // Initializes ScrollMagic with the GSAP animations plugin.
  ScrollMagicGsapPlugin(ScrollMagic, TweenMax);

  castle.initialize();

  await Promise.all([
    require(`intersection-observer`),
    // Optionally load Intl.PluralRules if not supported natively
    polyfillPluralRules(),
    // Delay rendering until translation data has been grabbed. This ensures
    // there is no flash of missing content when rehydrating from the server.
    preloadTranslations(),

    // Delay rendering until Launch Darkly has been initialized. This ensures
    // that the initial content is rendered with correct feature values instead
    // of default values, which prevents flickering.

    launchDarkly.initialize(),
  ]);
};

export const onInitialClientRender = () => {
  // Identify User to Bugsnag
  const userInfo = analyticsService.getStoredUserInfo();

  let userData = {
    id: userInfo.id || userInfo.anonymousId,
    email: userInfo.traits.email || null,
    name: userInfo.traits.firstName
      ? `${userInfo.traits.firstName} ${userInfo.traits.lastName}`
      : null,
  };
  Bugsnag.setUser(userData.id, userData.email, userData.name);
  Bugsnag.addMetadata("user", { anonymous: !userInfo.id });

  BugsnagPerformance.start({
    apiKey: process.env.GATSBY_BUGSNAG_API_KEY,
    releaseStage: process.env.GATSBY_BUGSNAG_RELEASE_STAGE,
    routingProvider: new DefaultRoutingProvider(resolveRoute),
  });

  Sentry.setUser({
    id: userInfo.id || null,
    email: userInfo.traits.email || null,
  });

  // @axe-core/react implementation
  if (
    process.env.NODE_ENV === "development" &&
    process.env.ENABLE_AXE_CORE === "true"
  ) {
    import("@axe-core/react").then((axe) => {
      axe.default(React, ReactDOM, 1000);
    });
  }
};

export const wrapPageElement = ({ element, props }) => {
  const { locale, activeLocales } = props.pageContext;
  return (
    <InitializeLocale locale={locale} activeLocales={activeLocales}>
      {element}
    </InitializeLocale>
  );
};

export const onPreRouteUpdate = ({ location, prevLocation }) => {
  // Reset the page label on transitions.
  currentPageData.label = null;
};

export const onRouteUpdate = ({ location, prevLocation }) => {
  const pageLabel = currentPageData.label;

  getStore().dispatch(updateRoute({ location, prevLocation }));

  if (!window.analytics || typeof window.analytics.page !== "function") {
    if (process.env.NODE_ENV === "development") {
      console.warn("Unable to locate analytics.js");
    }
    return;
  }

  metrics.page(pageLabel);
};
