import styled from "styled-components";
import Currency from "../Currency";
import StrikeText from "../global/StrikeText";
import { getPromotionDetails } from "../../utils/promotion";
import { responsive } from "../../utils/style";

const FloatText = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;

  ${responsive.sm`
    min-width: 231px;
  `}

  ${responsive.md`
    min-width: 246px;
  `}
`;

export default function DiscountButtonCopy({
  prefix,
  basePrice,
  price,
  showDash = true,
  allowCurrencyPostfix = true,
  showPricing = true,
  promotion,
  discountPrice,
  floatText = false,
}) {
  const promotionDetails = getPromotionDetails(promotion, price);
  const promotionDiscountPrice =
    discountPrice || promotionDetails.discountPrice || price;

  if (!showPricing) {
    return prefix;
  }

  if (floatText) {
    return (
      <FloatText>
        <div>{prefix}</div>
        <div>
          <StrikeText>
            {basePrice && basePrice !== price && (
              <Currency value={basePrice} round={true} />
            )}
          </StrikeText>{" "}
          <Currency value={promotionDiscountPrice} round={true} />
        </div>
      </FloatText>
    );
  }

  if (price === promotionDiscountPrice || promotionDetails.hideStrikethrough) {
    return (
      <span className={"d-flex"}>
        {prefix} {!!prefix && showDash && <>&#8212;</>}{" "}
        {price && (
          <Currency value={price} round={true} postfix={allowCurrencyPostfix} />
        )}
      </span>
    );
  }

  return (
    <>
      {prefix} {!!prefix && showDash && <>&#8212;</>}{" "}
      <Currency value={promotionDiscountPrice} round={true} />{" "}
      <StrikeText>
        {price && <Currency value={price} round={true} />}
      </StrikeText>
    </>
  );
}
