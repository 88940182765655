import { Component } from "react";
import styled from "styled-components";
import MagicLink from "../MagicLink";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { calculateReadingTime } from "../../utils/readingTime";
import {
  Color,
  rem,
  media,
  Font,
  maxLines,
  responsive,
} from "../../utils/style";
import Text from "../Text";

const ArticleContent = styled.div`
  height: 341px;
  max-width: 335px;
  position: relative;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: left;
  padding: ${rem(24)};
  background-color: ${(p) => p.background};

  &:hover img {
    transform: none;
  }

  ${responsive.sm`
    img {
      transition: all 0.2s ease-in-out !important;
    }

    &:hover {
      cursor: pointer;

      img {
        transform: scale(1.1);
      }
    }
  `}

  ${responsive.md`
    max-width: 452px;
    height: 401px;
    padding: ${rem(40)};
  `};

  ${responsive.lg`
    height: 341px;
  `};
`;

const Article = styled.div`
  align-self: flex-end;
  max-width: 320px;
  z-index: 2;

  span,
  p,
  h2 {
    color: ${(p) => (p.color ? p.color : Color.ritualBlue)};

    a {
      color: inherit;
    }
  }
`;

const ArticleReadtime = styled.span`
  ${Font.circular};
  display: block;
  font-size: ${rem(16)};
  line-height: ${rem(26)};
  font-weight: 500;
  text-align: left;
  margin: 0 0 ${rem(6)};
`;

const ArticleTitle = styled.h2`
  ${Font.circular}
  font-size: ${rem(22)};
  line-height: ${rem(30)};
  font-weight: 400;
  text-align: left;
  letter-spacing: -0.2px;
  margin: 0;

  a {
    color: inherit;

    &:hover {
      color: inherit;
    }
  }

  ${media.notDesktop`
    font-size: ${rem(22)};
  `}
`;

const ArticlePreview = styled.p`
  ${Font.dutch}
  font-size: ${rem(16)};
  line-height: ${rem(26)};
  font-weight: normal;
  text-align: left;
  margin: ${rem(16)} 0 0;
  ${maxLines(30, 3)};

  ${media.notDesktop`
    font-size: ${rem(16)};
    line-height: ${rem(26)};
    ${maxLines(28, 3)};
    margin-top: ${rem(18)};
  `}
`;

export default class CarouselArticle extends Component {
  render() {
    let {
      productSku,
      article,
      className,
      showCount,
      showPreview = false,
      index,
    } = this.props;
    let readTime = calculateReadingTime(article.primaryContent);

    return (
      <MagicLink
        to={article}
        state={productSku ? { productSku } : {}}
        ariaLabel={article.title}
      >
        <ArticleContent
          index={index}
          showCount={showCount}
          background={article.heroBackgroundColor}
          className={className}
        >
          <GatsbyImage
            className="d-none d-sm-block"
            image={getImage(article.heroImage.desktop)}
            color={article.heroBackgroundColor}
            alt={article.title}
            style={{
              position: "absolute",
              left: 0,
              top: 0,
              width: "100%",
              height: "100%",
              zIndex: "1",
              transition: "all 0.2s",
              userSelect: "none",
              userDrag: "none",
              pointerEvents: "none",
              touchCallout: "none",
            }}
          />
          <GatsbyImage
            className="d-block d-sm-none"
            image={getImage(article.heroImage.mobile)}
            color={article.heroBackgroundColor}
            alt={article.title}
            style={{
              position: "absolute",
              left: 0,
              top: 0,
              width: "100%",
              height: "100%",
              zIndex: "1",
              transition: "all 0.2s",
              userSelect: "none",
              userDrag: "none",
              pointerEvents: "none",
              touchCallout: "none",
            }}
          />
          <Article index={index} color={article.heroSecondaryColor}>
            <ArticleReadtime index={index}>
              <Text
                id="hub.featured-quote.read-time"
                defaultMessage="{readTime} min read"
                values={{ readTime }}
              />
            </ArticleReadtime>
            <ArticleTitle index={index}>{article.title}</ArticleTitle>
            {showPreview && (
              <ArticlePreview index={index}>
                {article.previewText}
              </ArticlePreview>
            )}
          </Article>
        </ArticleContent>
      </MagicLink>
    );
  }
}
