import styled from "styled-components";

// Components
import Container from "../Container";
import Row from "../Row";
import IngredientOverviewIngredientCard from "./IngredientOverviewIngredientCard";
import ProteinIngredientStories from "./protein/ProteinIngredientStories";
import Text from "../Text";
import { MagicSVGImage } from "../MagicSVGImage";

// Utils
import { Font, Color, rem, responsive, Opacity } from "../../utils/style";
import { IconArrowRight } from "../../utils/svg";
import metrics from "../../utils/metrics";
import QualityOverQuantity from "./protein/QualityOverQuantity";

// Styled Elements
const Wrapper = styled.div.attrs({
  role: "section",
  "aria-labelledby": "protein-ingredients-overview-header",
})`
  margin-bottom: var(--spacing-5, 80px);

  ${responsive.md`
    margin-bottom: 120px;
  `};
`;

const IngredientSectionContainer = styled(Container)`
  padding-bottom: var(--spacing-3, 48px);

  ${responsive.md`
    padding-bottom: var(--spacing-5, 80px);
  `};

  ${responsive.lg`
    padding-bottom: 120px;
  `};
`

const HeaderColumn = styled.div.attrs({
  className: "col-12 col-sm-8 offset-sm-2",
})`
  padding: 0 24px !important;

  ${responsive.sm`
    padding: 0 15px !important;
  `}
`;

const Header = styled.h2.attrs({
  id: "protein-ingredients-overview-header",
})`
  ${Font.circular};
  font-size: ${rem(30)};
  line-height: ${rem(36)};
  font-weight: 400;
  letter-spacing: -0.4px;
  text-align: left;
  color: ${Color.ritualBlue};
  margin: 0 auto 16px;

  ${responsive.sm`
    text-align: center;
  `}

  ${responsive.md`
    font-size: ${rem(48)};
    line-height: ${rem(54)};
    letter-spacing: -1px;
    margin: 0 0 24px;
  `}
`;

const Subhead = styled.p`
  ${Font.dutch};
  font-size: ${rem(16)};
  line-height: ${rem(26)};
  font-weight: normal;
  text-align: left;
  letter-spacing: 0;
  color: ${Color.ritualBlue};
  margin: 0;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  span {
    font: inherit;
  }

  ${responsive.sm`
    text-align: center !important;
  `}

  ${responsive.md`
    font-size: ${rem(18)};
    line-height: ${rem(28)};
  `}
`;

const IngredientsRow = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-3, 48px);
  
  ${responsive.md`
    gap: var(--spacing-5, 80px);
  `};

  ${responsive.lg`
    gap: 120px;
  `};
`;

const SupplementLink = styled.button.attrs({
  className: "typography-body1 font-dutch"
})`
  padding: 0;
  background: none;
  border: none;
  color: var(--indigo-blue, #142b6f);
  border-bottom: 1px solid var(--indigo-blue, #142b6f);
  cursor: pointer;
  transition: opacity 200ms ease-in-out;
  line-height: ${rem(26)};

  &:hover {
    opacity: ${Opacity.light};
  }
`;

const MobileSupplementLink = styled.button.attrs({
  className: "typography-eyebrow1"
})`
  color: var(--indigo-blue, #142b6f);
  margin-top: var(--spacing-1, 16px);
  align-self: flex-start;
  display: flex;
  gap: var(--spacing-0_75, 12px);

  &:hover {
    opacity: ${Opacity.light};
  }
`;

const OtherIngredients = styled.div.attrs({
  className: "col-12",
})`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 24px;
  margin-bottom: 80px;

  ${responsive.md`
    margin-bottom: 120px;
  `};

  p {
    text-align: center;
    margin: 0;
    max-width: 900px;
  }
`;

const IngredientsOverviewSection = (props: any) => {
  const {
    product,
    ingredients,
    ingredientsSubheader,
    showQualityOverQuantity = true,
    showOtherIngredients = true,
    openFlyout = () => {},
    isMobile
  } = props;

  const handlePopup = () => {
    return openFlyout("Supplement Facts");
  }

  const clickEvent = (ingredient: any) => {
    metrics.track("Ingredient Card Clicked", {
      name: ingredient.name,
      location: "Ingredient List",
    });
  }

  if (!ingredients || !ingredients.length) {
    return null;
  }

  const visibleCards = 3;

  const qualityOverQuantityIngredients = ingredients.slice(
    ingredients.length - 2,
  );

  const otherIngredients = product.supplementFacts.otherIngredients.replace(
    "**Derived from Non-GMO Sunflower",
    "",
  );

  return (
    <Wrapper>
      <IngredientSectionContainer>
        <Row>
          <HeaderColumn>
            <Header>
              <Text
                id="product.protein.ingredients.header"
                defaultMessage="Traceable Ingredients"
              />
            </Header>
            <Subhead>
              {ingredientsSubheader.childMarkdownRemark.rawMarkdownBody}{" "}
              {isMobile ?
                <MobileSupplementLink onClick={handlePopup}>
                  <Text
                    id="product.ingredients.view-facts"
                    defaultMessage="View Supplement Facts"
                  />
                  <MagicSVGImage
                    src={IconArrowRight}
                    width={16}
                    height={16}
                    alt="Arrow Right"
                  />
                </MobileSupplementLink>
              : <SupplementLink onClick={handlePopup}>
                  <Text
                    id="product.ingredients.view-facts"
                    defaultMessage="View Supplement Facts"
                  />
                </SupplementLink>
              }
            </Subhead>
          </HeaderColumn>
        </Row>
      </IngredientSectionContainer>
      <IngredientsRow>
        {ingredients.slice(0, visibleCards).map((ingredient: any, index: number) => {
          return (
            <>
              <IngredientOverviewIngredientCard
                index={index}
                product={product}
                ingredient={ingredient}
                handleClick={clickEvent}
              />
              <ProteinIngredientStories ingredient={ingredient} />
            </>
          );
        })}
      </IngredientsRow>
      {showQualityOverQuantity && ( // TODO: Extract this out as an option in Contentful
        <QualityOverQuantity ingredients={qualityOverQuantityIngredients} />
      )}

      {showOtherIngredients && (
        <Container>
          <Row>
            <OtherIngredients>
              {product.supplementFacts && (
                <p data-test-other-ingredients>
                  <Text
                    id="product.protein.ingredients.other-ingredients"
                    defaultMessage={`Ingredients: ${otherIngredients}`}
                    values={{
                      otherIngredients,
                    }}
                  />
                </p>
              )}
            </OtherIngredients>
          </Row>
        </Container>
      )}
    </Wrapper>
  );
}

export default IngredientsOverviewSection;
