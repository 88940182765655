import getBrowserLocale from "./browserLocale";
import intl from "../services/intl";

export function detectLocale() {
  return getBrowserLocale();
}

/**
 *  Returns the first locale
 *  for a given country code such as "US".
 */
function findLocaleForCountry(country) {
  return intl.activeLocales.find((e) => e.country === country);
}

/**
 *  Returns either the direct locale found
 *  or the first locale configured with an alias
 *  for that locale.
 */
function findLocaleForSuggestion(locale) {
  return intl.activeLocales.find(
    (e) =>
      locale === e.locale ||
      (Array.isArray(e.aliases) && e.aliases.includes(locale)),
  );
}

/**
 * Queries the Cloudflare trace endpoint
 * to ascertain the detected country for
 * the user based on their IP.
 */
async function detectCountry() {
  try {
    const endpoint = `${process.env.GATSBY_CLOUDFLARE_HOST}/cdn-cgi/trace`;
    const request = await fetch(endpoint);
    const response = await request.text();
    const lines = response.split("\n");

    let keyValue;
    let trace = [];

    lines.forEach((line) => {
      keyValue = line.split("=");
      trace[keyValue[0]] = decodeURIComponent(keyValue[1] || "");
    });

    return trace.loc;
  } catch (e) {
    return null;
  }
}

/**
 * Makes a locale suggestion, taking into consideration the
 * detected country and the browser accept-language
 * setting.
 */
export async function suggestLocale(navigatorLocale) {
  if (typeof window === "undefined") return null;
  if (!navigatorLocale) navigatorLocale = detectLocale();
  const country = await detectCountry();

  const geoLocalePrediction =
    country && navigatorLocale && navigatorLocale.split("-")[0] + "-" + country;

  const found =
    findLocaleForSuggestion(geoLocalePrediction) ||
    findLocaleForCountry(country) ||
    findLocaleForSuggestion(navigatorLocale);

  if (intl.canToggleLocales && found && found.locale !== intl.locale)
    return found.locale;

  return null;
}
