import { useEffect, useRef, useState } from "react";
import styled from "styled-components";

interface FloatingInputProps {
  placeholder: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onClick?: () => void;
  onBlur?: () => void;
  error?: string;
  buttonText?: string;
  minLength?: number;
  maxLength?: number;
  type?: "text" | "password";
}

const FloatingInputContainer = styled.label`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: var(--spacing-0_5) var(--spacing-1);
  border: 1px solid var(--indigo-blue-20);
  border-radius: var(--spacing-0_25);
  height: 59px;
  margin: 0;
  transition: border-color 0.3s;
  background-color: var(--white);
  position: relative;

  &:hover,
  &.focused {
    border-color: var(--indigo-blue);
  }

  &.error {
    border-color: var(--error-red);
  }

  & > div {
    width: 100%;

    input {
      font-family: var(--font-circular);
      width: 100%;
      border: none;
      appearance: none;
      padding: 0;
      margin: 0;
      background: none;
      color: var(--indigo-blue);
      font-size: var(--spacing-1);
      line-height: var(--spacing-1_5);
      font-weight: 450;

      &:focus {
        outline: none;
      }

      &.floating {
        transform: translateY(10px);
      }
    }

    .placeholder {
      font-size: var(--spacing-1);
      line-height: var(--spacing-1_5);
      font-weight: 450;
      color: var(--indigo-blue);
      position: absolute;
      pointer-events: none;
      font-family: var(--font-circular);

      transition: transform 0.3s, font-size 0.3s, line-height 0.3s;

      &.floating {
        font-size: 14px;
        line-height: var(--spacing-1_5);
        color: var(--indigo-blue-60);
        transform: translateY(-12px);
      }
    }
  }

  button {
    font-family: var(--font-circular);
    border: none;
    appearance: none;
    padding: 0;
    margin: 0 0 0 var(--spacing-1);
    background: none;
    color: var(--indigo-blue);
    border-bottom: 2px solid var(--indigo-blue);
    font-weight: 500;
    font-size: 14px;
    cursor: pointer;

    &:disabled {
      color: var(--indigo-blue-40);
      border-color: var(--indigo-blue-40);
      cursor: not-allowed;
    }
  }
`;

const FloatingInput = ({
  placeholder,
  value,
  onChange,
  onClick,
  onBlur,
  error,
  buttonText,
  minLength = 0,
  maxLength = 50,
  type = "text",
}: FloatingInputProps) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [floating, setFloating] = useState(false);
  const [focus, setFocus] = useState(false);

  useEffect(() => {
    if (value && value.length > 0) {
      setFloating(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!value && !focus) {
      setFloating(false);
    }
  }, [value, focus]);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (!focus || !onClick) return;

    if (event.key === "Enter") {
      onClick();
    }
  };

  return (
    <FloatingInputContainer
      className={`${focus ? "focused" : ""} ${error ? "error" : ""}`}
    >
      <div>
        <span
          className={`placeholder ${focus ? "focused" : ""} ${
            floating || value ? "floating" : ""
          }`}
        >
          {placeholder}
        </span>
        <input
          type={type}
          value={value}
          onChange={onChange}
          onFocus={() => {
            setFocus(true);
            setFloating(true);
          }}
          onBlur={() => {
            setFloating(!!value);
            setFocus(false);
            if (onBlur) onBlur();
          }}
          ref={inputRef}
          aria-placeholder={placeholder}
          aria-label={placeholder}
          placeholder={placeholder}
          className={floating ? "floating" : ""}
          minLength={minLength}
          maxLength={maxLength}
          onKeyDown={handleKeyDown}
        />
      </div>
      {buttonText && onClick && (
        <button disabled={!value} onClick={onClick}>
          {buttonText}
        </button>
      )}
    </FloatingInputContainer>
  );
};

export default FloatingInput;
