import { useEffect, useState } from "react";
import { navigate } from "gatsby";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { Helmet } from "react-helmet";
import { RBadge } from "@ritual/essentials-for-react";

import GridColumn from "../../components/grid/GridColumn";
import GridContainer from "../../components/grid/GridContainer";
import GridRow from "../../components/grid/GridRow";

import Cart from "../../store/cart/model";
import { fetchCart } from "../../store/cart/actions";

import useVariation from "../../hooks/useVariation";
import { getCookie } from "../../utils/cookies";
import { responsive } from "../../utils/style";
import { trackOrderCompleted } from "../../utils/tracking/cart";
import intl from "../../services/intl";
import locales from "../../../config/locales";
import { estimateShippingAndDeliveryDates } from "../../components/confirmation/utils";

import PostPurchaseSurvey from "../../components/checkout/confirmation/PostPurchaseSurvey";
import CartLedger from "../../components/checkout/CartLedger";
import PizzaTracker from "../../components/confirmation/PizzaTracker";
import SMSSignup from "../../components/confirmation/SMSSignup";
import AccountSetup from "../../components/confirmation/AccountSetup";
import Promotion from "../../store/promotion/model";

const PageWrapper = styled.div`
  margin-top: 56px;
  background-color: var(--warm-20);
  padding: var(--spacing-2) 0;
  overflow: hidden;
  position: relative;

  @media (min-width: 750px) {
    padding: var(--spacing-3) 0;
    margin-top: 64px;
  }

  & > * {
    position: relative;
    z-index: 2;
  }
`;

const TopRadialGradient = styled.div`
  display: block;
  width: 471px;
  height: 471px;
  z-index: 1;

  position: absolute;
  left: -155px;
  top: -155px;

  ${responsive.md`
    width: 1200px;
    height: 1200px;
    left: -600px;
    top: -640px;
  `};

  background: radial-gradient(
    50% 50% at 50% 50%,
    #ffd600 0%,
    rgba(255, 214, 0, 0) 100%
  );
`;

const Header = styled.div`
  text-align: center;
  margin-bottom: var(--spacing-2);

  @media (min-width: 750px) {
    text-align: left;
  }

  h1 {
    margin: var(--spacing-1) 0;
  }

  p {
    margin-bottom: var(--spacing-2);
  }

  @media (min-width: 750px) {
    margin-bottom: 0;
  }
`;

const GridGap1 = () => (
  <GridColumn
    xs={{ size: 6, hide: true }}
    s={{ size: 12, hide: true }}
    m={{ size: 1 }}
  ></GridColumn>
);

const markCartAsPurchased = async () => {
  const cartId = sessionStorage.getItem("rit-cart_id");

  const checkoutType = sessionStorage.getItem("rit-checkout");
  const afterPurchaseAPIPath =
    checkoutType === "home"
      ? "purchase/finalize"
      : "express_checkout/mark_purchased";
  await fetch(
    `${process.env.GATSBY_RITUAL_API_URL}/api/v1/${afterPurchaseAPIPath}`,
    {
      method: "POST",
      mode: "cors",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ cart_id: cartId }),
    },
  );
};

const Confirmation = () => {
  const dispatch = useDispatch();
  const ppsCookie = getCookie("rit_survey");
  const showPostPurchaseSurvey =
    useVariation("post-purchase-survey") && !ppsCookie;
  const [orderCompleted, setOrderCompleted] = useState(false);

  useEffect(() => {
    markCartAsPurchased().then(() => {
      const checkoutTypeHome = sessionStorage
        .getItem("rit-checkout")
        ?.includes("home");
      if (checkoutTypeHome) {
        dispatch(fetchCart());
      }
    });

    sessionStorage.removeItem("rit-payment_intent_client_secret");

    const orderNumber = sessionStorage.getItem("rit-order_number");
    const purchasedCart = JSON.parse(
      sessionStorage.getItem("rit-purchased_cart") || "{}",
    );

    const purchasedCartProducts = JSON.parse(
      sessionStorage.getItem("rit-purchased_cart_products") || "[]",
    );
    const appliedPromotionSession = sessionStorage.getItem(
      "rit-applied_promotion",
    );
    const appliedPromotion =
      appliedPromotionSession && appliedPromotionSession !== "undefined"
        ? JSON.parse(appliedPromotionSession)
        : null;

    const marketingPreference = sessionStorage
      .getItem("rit-marketing_preference")
      ?.includes("true");

    if (orderNumber && !orderCompleted) {
      setOrderCompleted(true);
      trackOrderCompleted(
        orderNumber,
        purchasedCart,
        purchasedCartProducts,
        appliedPromotion,
        {
          addMarketingPreference: true,
          marketingPreference,
        },
      );
    }

    const clearSessionStorage = () => {
      sessionStorage.removeItem("rit-cart_id");
      sessionStorage.removeItem("rit-marketing_preference");
      sessionStorage.removeItem("rit-reset_password_token");
      sessionStorage.removeItem("rit-express_checkout_confirm_event");
      sessionStorage.removeItem("rit-purchased_cart");
      sessionStorage.removeItem("rit-purchased_cart_products");
      sessionStorage.removeItem("rit-order_number");
      sessionStorage.removeItem("rit-subscription_id");
    };

    window.addEventListener("beforeunload", clearSessionStorage);

    return () => {
      window.removeEventListener("beforeunload", clearSessionStorage);
    };
  }, [dispatch, orderCompleted]);

  if (typeof window === "undefined") {
    return null;
  }

  const cartId = sessionStorage.getItem("rit-cart_id");
  const expressCheckoutConfirmEvent = JSON.parse(
    sessionStorage.getItem("rit-express_checkout_confirm_event") || "{}",
  );

  if (!cartId || !expressCheckoutConfirmEvent) {
    navigate("/cart");
    return null;
  }

  const resetPasswordToken = sessionStorage.getItem("rit-reset_password_token");
  const initialFirstName = sessionStorage.getItem("rit-first_name");
  const initialLastName = sessionStorage.getItem("rit-last_name");

  const orderNumber = sessionStorage.getItem("rit-order_number");
  const purchasedCart = JSON.parse(
    sessionStorage.getItem("rit-purchased_cart") || "{}",
  );
  const normalizedCart = new Cart(purchasedCart);
  const purchasedCartProducts = JSON.parse(
    sessionStorage.getItem("rit-purchased_cart_products") || "[]",
  );

  const appliedPromotionSession = sessionStorage.getItem(
    "rit-applied_promotion",
  );
  const appliedPromotion =
    appliedPromotionSession && appliedPromotionSession !== "undefined"
      ? new Promotion(JSON.parse(appliedPromotionSession)?.attributes)
      : null;

  const localeId = intl.locale;
  const locale = locales[localeId]!;
  const shippingEstimates = estimateShippingAndDeliveryDates(
    locale,
    new Date(),
  );

  return (
    <>
      <Helmet>
        <title>Order confirmation | Ritual</title>
      </Helmet>
      <PageWrapper>
        <TopRadialGradient />
        <GridContainer>
          <GridRow>
            <GridGap1 />
            <GridColumn xs={{ size: 6 }} s={{ size: 12 }} m={{ size: 7 }}>
              <Header>
                <RBadge badgeColor="white">Order #{orderNumber}</RBadge>
                <h1 className="typography-headline2">
                  <span className="font-dutch-italic">Welcome</span> to your new
                  Ritual.
                </h1>
                <p className="typography-body1">
                  Get excited—we're getting your order together. Check your
                  inbox at {expressCheckoutConfirmEvent.billingDetails.email}{" "}
                  for updates about your order and unlock account features
                  below.
                </p>
                <PizzaTracker {...shippingEstimates} />
              </Header>
            </GridColumn>
          </GridRow>
          <GridRow addClass="smaller-mobile-gap">
            <GridGap1 />
            <GridColumn
              xs={{ size: 6, order: 0 }}
              s={{ size: 12, order: 0 }}
              m={{ size: 4, order: 1 }}
            >
              <CartLedger
                activeCart={normalizedCart}
                activeCartProducts={purchasedCartProducts}
                activeCoupon={undefined}
                appliedPromotion={appliedPromotion}
                isProcessing={false}
                hideCouponGiftCards={true}
                showMarginMobile={true}
                shippingAddress={purchasedCart.shipping_address}
                firstName={initialFirstName}
                lastName={initialLastName}
              />
            </GridColumn>

            <GridColumn
              xs={{ size: 6, order: 1 }}
              s={{ size: 12, order: 1 }}
              m={{ size: 6, order: 0 }}
            >
              {resetPasswordToken && (
                <AccountSetup
                  token={resetPasswordToken}
                  initialFirstName={initialFirstName}
                  initialLastName={initialLastName}
                />
              )}
              <SMSSignup country={locale.storeCode} />
            </GridColumn>
          </GridRow>
        </GridContainer>
      </PageWrapper>
      {showPostPurchaseSurvey && <PostPurchaseSurvey />}
    </>
  );
};

export default Confirmation;
