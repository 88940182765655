import styled from "styled-components";

const RowElement = styled.div`
  &.grid-row-plp {
    row-gap: var(--spacing-2, 32px) !important;

    @media (min-width: 750px /* Grid S Tablet */) {
      row-gap: var(--Between-Modules-Only-spacing-4, 64px) !important;
    }
  }

  &.smaller-mobile-gap {
    @media (max-width: 786px /* Grid XS Mobile */) {
      row-gap: 24px !important;
    }
  }
`;

const GridRow = ({
  children,
  addClass,
}: {
  children: React.ReactNode;
  addClass?: string;
}) => {
  return (
    <RowElement className={`r-grid-row ${addClass ? addClass : ""}`}>
      {children}
    </RowElement>
  );
};

export default GridRow;
