import { Component } from "react";
import styled from "styled-components";

// Components
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import MagicLink from "../MagicLink";
import Text from "../Text";

// Utils
import { Color, Font, rem, responsive, Opacity } from "../../utils/style";
import findDosageByProduct from "../../utils/findDosageByProduct";

const SlideWrapper = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  background-color: #f2f1f6;
`;

const SlideContent = styled.div`
  position: relative;
  z-index: 10;
  width: 100%;
  height: 100%;
  padding: 24px 24px 40px;

  ${responsive.sm`
    padding: 40px 60px 64px;
  `};

  ${responsive.md`
    padding: 56px 80px 64px;
  `};

  ${responsive.lg`
    padding: 56px 100px 64px;
  `};
`;

const IngredientTitle = styled.h2`
  ${Font.circular};
  font-size: ${rem(22)};
  line-height: ${rem(32)};
  letter-spacing: -0.2px;
  font-weight: 500;
  margin: 0;
`;

const IngredientDosage = styled.p`
  ${Font.circular};
  font-size: ${rem(14)};
  line-height: ${rem(20)};
  font-weight: 300;
  margin: 0 0 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid #ccc;

  ${responsive.md`
    margin-bottom: 24px;
    padding-bottom: 24px;
  `};
`;

const IngredientDescription = styled.p`
  ${Font.circular};
  font-size: ${rem(14)};
  line-height: ${rem(20)};
  font-weight: 300;
  margin: 0 0 24px;

  ${responsive.md`
    margin-bottom: 32px;
  `};
`;

const NutritionFactTitle = styled.div`
  ${Font.circular};
  font-size: ${rem(12)};
  line-height: ${rem(20)};
  letter-spacing: 0.8px;
  font-weight: 500;
  margin: 0 0 8px;
  color: rgba(20, 43, 111, ${Opacity.light});
  text-transform: uppercase;
`;

const NutritionFact = styled.p`
  ${Font.circular};
  font-size: ${rem(14)};
  line-height: ${rem(20)};
  font-weight: 300;
  margin: 0 0 24px;
`;

export const LearnMore = styled(MagicLink)`
  border-bottom: 2px solid ${Color.ritualBlue};
  font-size: ${rem(14)};
  position: absolute;
  bottom: 40px;
  left: 24px;

  ${responsive.sm`
    bottom: 64px;
    left: 60px;
  `};

  ${responsive.md`
    bottom: 64px;
    left: 80px;
  `};

  ${responsive.lg`
    bottom: 64px;
    left: 100px;
  `};
`;

export default class IngredientsOverviewSlide extends Component {
  render() {
    const { ingredient, product, id, selectedPlanId, isMobile } = this.props;
    const {
      name,
      modalDesktopImage,
      modalMobileImage,
      slug,
      modalDescription,
      nutritionFact,
    } = ingredient;

    const dosage = findDosageByProduct(ingredient, product);

    const learnMoreLink = `/ingredients/${slug}`;

    return (
      <SlideWrapper id={id} aria-label={name}>
        <SlideContent>
          <IngredientTitle>{name}</IngredientTitle>
          <IngredientDosage>{dosage}</IngredientDosage>
          <IngredientDescription>{modalDescription}</IngredientDescription>
          <NutritionFactTitle>
            <Text
              id="product.ingredients.nutrition-fact"
              defaultMessage="Nutrition Fact"
            />
          </NutritionFactTitle>
          <NutritionFact>{nutritionFact}</NutritionFact>
          {slug && (
            <LearnMore to={learnMoreLink} state={{ productSku: product.sku, selectedPlanId: selectedPlanId, ctfProduct: product, isMobile: isMobile }}>
              <Text id="general.learn-more" defaultMessage="Learn More" />
            </LearnMore>
          )}
        </SlideContent>

        <GatsbyImage
          className="d-block d-sm-none"
          image={getImage(modalMobileImage)}
          alt={name}
          style={{
            position: "absolute",
            top: "0",
            right: "0",
            bottom: "0",
            left: "0",
            userSelect: "none",
            userDrag: "none",
            pointerEvents: "none",
            touchCallout: "none",
          }}
        />
        <GatsbyImage
          className="d-none d-sm-block"
          image={getImage(modalDesktopImage)}
          alt={name}
          style={{
            position: "absolute",
            top: "0",
            right: "0",
            bottom: "0",
            left: "0",
            userSelect: "none",
            userDrag: "none",
            pointerEvents: "none",
            touchCallout: "none",
          }}
        />
      </SlideWrapper>
    );
  }
}
