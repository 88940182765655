import intl from "../services/intl";
import { variation } from "../services/launchDarkly";

export function isProductSpecificPromotion(promotion = {}) {
  return ["Postnatal"].includes(promotion.name);
}

export const isRecurringBundlePromotion = (promotion = {}) =>
  promotion.name === "5% Bundle Discount";

function limitBy(amount, maxDiscountAmount) {
  return amount < maxDiscountAmount ? amount : maxDiscountAmount;
}

// Dont show strikethrough for regular price or long-lived welcome-offer promo
function hideStrikethrough(promotion) {
  return (
    promotion?.name === "welcome" &&
    variation("hide-welcome-offer-strikethrough")
  );
}

export function getPromotionDetails(promotion, price) {
  if (!promotion) return {};

  const { amountOff, maxDiscount, percentOff } = promotion;

  const maxDiscountAmount = maxDiscount ? maxDiscount / 100 : Infinity;

  const dollarAmount = maxDiscount
    ? limitBy(amountOff / 100, maxDiscountAmount)
    : amountOff / 100;

  let useFixedDiscount = true;
  let percentDiscount;

  const fixedDiscount = price - dollarAmount;

  if (percentOff) {
    percentDiscount =
      price - limitBy(price * (percentOff / 100), maxDiscountAmount);
    useFixedDiscount = amountOff && fixedDiscount > percentDiscount;
  }

  const formattedValue = useFixedDiscount
    ? intl.formatCurrency(dollarAmount, { round: true })
    : `${percentOff}%`;

  return {
    titleCopy: intl.t("promo.discount.title", "Save {value}", {
      value: formattedValue,
    }),
    price,
    discountPrice: price
      ? useFixedDiscount
        ? fixedDiscount
        : percentDiscount
      : null,
    formattedValue: formattedValue,
    amountOff: useFixedDiscount ? dollarAmount : null,
    percentOff: useFixedDiscount ? null : percentOff,
    maxDiscount: maxDiscountAmount === Infinity ? null : maxDiscountAmount,
    hideStrikethrough: hideStrikethrough(promotion),
    promotion,
  };
}
