import { Fragment, useState, useEffect } from "react";
import styled, { keyframes } from "styled-components";

// Utils
import { useInView } from "react-intersection-observer";
import { Font, rem, media, responsive } from "../../utils/style";
import { Icons } from "../../utils/react-svg";
import intl from "../../services/intl";

// Components
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import TestimonialsCarouselVideos from "./TestimonialsCarouselVideos";
import Swipeable from "../Swipeable";

// Constants
const TRANSITION_DURATION = 400;

// Styled Elements
const CarouselContainer = styled.div`
  position: relative;
  display: flex;
  margin-top: 40px;

  @media (max-width: 600px) {
    flex-direction: column-reverse;
    margin: 40px -20px 0;
    padding: 0 20px;
    overflow: hidden;
  }

  ${responsive.md`
    margin-top: 56px;
  `};
`;

const Spacer = styled.div.attrs({
  className: "col-3 col-md-3",
})`
  min-width: 300px;

  ${media.tablet`
    min-width: unset;
  `};

  @media (max-width: 600px) {
    display: none;
  }
`;

const Name = styled.div`
  margin-bottom: 6px;
  font-size: ${rem(18)};

  ${media.mobile`
    margin-bottom: 2px;
  `};
`;

const Title = styled.div`
  ${Font.dutch};
  font-style: italic;
  font-weight: 500;
  font-size: ${rem(16)};
  margin-bottom: 30px;

  ${media.tablet`
    font-size: ${rem(14)}
    margin-bottom: 24px;
  `};

  ${media.mobile`
    margin-bottom: 0;
  `};
`;

const TextContainer = styled.div.attrs({
  id: "testimonials-new-carousel_text-container",
})`
  position: absolute;
  display: flex;
  flex-direction: column;
  z-index: 60;
  padding-top: 56px;
  width: 370px;

  ${media.tablet`
    padding-top: 24px;
    width: 220px;
  `};

  ${media.mobile`
    width: 256px;

    ${Name},
    ${Title} {
      display: none
    }
  `};

  @media (max-width: 600px) {
    position: relative;
  }
`;

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const moveDown = keyframes`
  from { transform: translateY(-20%) }
  to { transform: translateY(0) }
`;

const moveUp = keyframes`
  from { transform: translateY(20%) }
  to { transform: translateY(0) }
`;

const moveDownMobile = keyframes`
  from { transform: translateY(-50%) }
  to { transform: translateY(0) }
`;

const moveUpMobile = keyframes`
  from { transform: translateY(50%) }
  to { transform: translateY(0) }
`;

const AnimateOnChange = styled.div`
  opacity: 0;
  animation: ${fadeIn} ${TRANSITION_DURATION}ms 100ms forwards,
    ${(p) => (p.direction === "forwards" ? moveDown : moveUp)}
      ${TRANSITION_DURATION}ms cubic-bezier(0.215, 0.61, 0.355, 1) forwards;

  ${media.mobile`
      animation: ${fadeIn} ${TRANSITION_DURATION}ms 100ms forwards,
    ${(p) => (p.direction === "forwards" ? moveDownMobile : moveUpMobile)}
      ${TRANSITION_DURATION}ms cubic-bezier(0.215, 0.61, 0.355, 1) forwards;
  `};
`;

const PullQuote = styled.div.attrs({
  id: "testimonials-new-carousel_text-container_quote",
})`
  position: relative;
  font-size: ${rem(34)};
  line-height: ${rem(40)};
  letter-spacing: -0.5px;
  margin-bottom: 24px;
  min-height: 120px;

  ${media.tablet`
    font-size: ${rem(22)};
    line-height: ${rem(32)};
    min-height: 96px;
    margin-bottom: 16px;
  `};

  ${media.mobile`
    margin-bottom: 0;
  `};
`;

const Controls = styled.div.attrs({
  id: "testimonials-new-carousel_slides_controls",
})`
  position: absolute;
  z-index: 70;
  bottom: 30px;
  left: 58.5%;

  ${responsive.md`
    bottom: 30px;
    left: 60.5%;
  `};

  ${responsive.lg`
    bottom: 70px;
    left: 62%;
  `};

  @media (max-width: 600px) {
    display: none;
  }
`;

const ControlButton = styled.button.attrs((p) => ({
  id: `testimonials-new-carousel_slides_controls_control-button-${p.index}`,
}))`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  border: none;
  padding: 0;
  width: 57px;
  height: 56px;
  background: #f8f7f9;

  ${responsive.md`
    width: 60px;
  `};

  ${responsive.lg`
    width: 62px;
  `};

  [data-whatintent="mouse"] &:focus,
  [data-whatintent="touch"] &:focus {
    outline: none;
  }

  @media (max-width: 600px) {
    background: transparent;
  }

  svg {
    width: 18px;
    height: 12px;
    transition: transform 0.2s;
  }

  &:hover {
    svg {
      transform: scale(1.1666);
    }
  }
`;

const MobileControlsButton = styled.div`
  position: absolute;
  right: 0;
  height: 100%;
  width: 20%;
  display: flex;
  justify-content: flex-end;
  background: transparent;
  border: none;
  z-index: 100;

  @media (min-width: 600px) {
    display: none;
  }
`;

const PlayButton = styled.button.attrs({
  id: "testimonials-new-carousel_slides_play-button",
})`
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 24px;
  left: 24px;
  padding: 0;
  z-index: 50;
  background: transparent;
  border: none;
  border-radius: 50%;
  color: #fff;
  text-align: left;
  opacity: 0;
  animation: ${fadeIn} ${TRANSITION_DURATION}ms 100ms forwards;
  pointer-events: none;
  transition: background 200ms ease;

  @media (min-width: 749px) {
    ${Name}, ${Title} {
      display: none;
    }
  }

  [data-whatintent="mouse"] &:focus,
  [data-whatintent="touch"] &:focus {
    outline: none;
  }

  ${media.tablet`
    left: 16px;
    bottom: 16px;
    border-radius: 0;
  `};

  @media (max-width: 748px) {
    bottom: 0;
    left: 0;
    width: 100%;
    max-width: 256px;
    padding-left: 16px;
    padding-bottom: 16px;
    background: -moz-linear-gradient(
      top,
      rgba(68, 68, 68, 0) 0%,
      rgba(68, 68, 68, 0.25) 100%
    );
    background: -webkit-linear-gradient(
      top,
      rgba(68, 68, 68, 0) 0%,
      rgba(68, 68, 68, 0.25) 100%
    );
    background: linear-gradient(
      to bottom,
      rgba(68, 68, 68, 0) 0%,
      rgba(68, 68, 68, 0.25) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00444444', endColorstr='#40444444',GradientType=0 );
  }

  @media (min-width: 601px) and (max-width: 748px) {
    max-width: 220px;
  }

  /* PlayButtonIcon as SVG */
  svg {
    width: 40px;
    height: 40px;
    margin-bottom: 8px;

    ${responsive.sm`
      width: 48px;
      height: 48px;
      overflow: visible;
    `};

    ${responsive.md`
      width: 60px;
      height: 60px;
      margin: 0;
    `};
  }
`;

const PlayButtonIcon = () => (
  <svg
    width="60"
    height="60"
    viewBox="0 0 60 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M30 57.5C45.1878 57.5 57.5 45.1878 57.5 30C57.5 14.8122 45.1878 2.5 30 2.5C14.8122 2.5 2.5 14.8122 2.5 30C2.5 45.1878 14.8122 57.5 30 57.5ZM60 30C60 46.5685 46.5685 60 30 60C13.4315 60 0 46.5685 0 30C0 13.4315 13.4315 0 30 0C46.5685 0 60 13.4315 60 30Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M27.0976 22.1153C26.8802 21.9748 26.6021 21.9619 26.3734 22.0812C26.1433 22.1998 26 22.4309 26 22.6825V36.3182C26 36.5698 26.1433 36.8009 26.3727 36.9195C26.4772 36.9734 26.5915 37 26.7059 37C26.8428 37 26.9791 36.9611 27.0976 36.8855L37.6859 30.0676C37.8821 29.9408 38 29.7281 38 29.5004C38 29.2726 37.8821 29.0599 37.6859 28.9331L27.0976 22.1153Z"
      fill="white"
    />
  </svg>
);

const Slides = styled.div.attrs({
  id: "testimonials-new-carousel_slides",
})`
  display: flex;
  position: relative;
`;

const Slide = styled.div.attrs((p) => ({
  id: `testimonials-new-carousel_slides_slide-${p.index}`,
}))`
  position: relative;
  width: 289px;
  height: 351px;
  cursor: pointer;
  overflow: hidden;

  &:first-child {
    width: 375px;
    height: 450px;

    &:hover ~ ${PlayButton} {
      background: rgba(255, 255, 255, 0.2);
    }
  }

  &:not(:last-child) {
    margin-right: 30px;
  }

  ${media.notDesktop`
    width: 229px;
    height: 279px;

    &:first-child {
      width: 294px;
      height: 358px;
    }

    &:not(:last-child) {
      margin-right: 26px;
    }
  `};

  ${media.tablet`
    width: 172px;
    height: 209px;

    &:first-child {
      width: 220px;
      height: 267px;

      &:hover ~ ${PlayButton} {
        background: transparent;
      }
    }

    &:not(:last-child) {
      margin-right: 20px;
    }
  `};

  @media (max-width: 600px) {
    min-width: 200px;
    min-height: 242px;

    &:first-child {
      min-width: 256px;
      min-height: 310px;
    }

    &:last-child {
      display: none;
    }

    &:not(:last-child) {
      margin-right: 16px;
    }
  }
`;

const ImageWrapper = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0px;
  left: 0px;
  color: #000;
  visibility: hidden;
  z-index: 20;
  will-change: transform;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.25);
  transition: transform ${TRANSITION_DURATION}ms cubic-bezier(0.4, 0, 0.6, 1);
`;

function TestimonialsCarousel({ slides }) {
  const initialState = {
    activeSlideIndex: 0,
    direction: "forwards",
  };

  const [{ activeSlideIndex, direction }, setSlide] = useState(initialState);
  const [controlsAreLocked, setLockControls] = useState(false);
  const [showVideos, setShowVideos] = useState(false);
  const [ref, inView] = useInView();

  const numberOfSlideSections = new Array(3).fill("");
  const numberOfSlides = slides.length;
  const activeSlide = slides[activeSlideIndex];

  useEffect(() => {
    if (controlsAreLocked) return;

    if (inView) {
      const handleKeyDown = function (event) {
        switch (event.key) {
          case "ArrowLeft":
            handleGoToPreviousSlide();
            break;
          case "ArrowRight":
            handleGoToNextSlide();
            break;
          default:
            break;
        }
      };
      window.addEventListener("keydown", handleKeyDown);
      return () => window.removeEventListener("keydown", handleKeyDown);
    }
  }, [controlsAreLocked, inView]);

  /**
   * In theory one could keep clicking and repeatedly very fast and break
   * the carousel since the transition takes about 350ms. That's why we
   * need to lock the controls for about 350ms and then unlock them.
   */
  function handleLockingControls() {
    if (controlsAreLocked) return;

    setLockControls(true);
    setTimeout(() => setLockControls(false), TRANSITION_DURATION);
  }

  /**
   * We need to update the active slide index and let the carousel know
   * we are going forwards. This will trigger an animation when updated.
   */
  function handleGoToNextSlide() {
    if (controlsAreLocked) return;

    setSlide(({ activeSlideIndex }) => {
      handleLockingControls();

      // Can't get the activeSlideIndex out of bounds of the slides
      if (activeSlideIndex === numberOfSlides - 1) {
        return { activeSlideIndex: 0, direction: "forwards" };
      } else {
        return {
          activeSlideIndex: activeSlideIndex + 1,
          direction: "forwards",
        };
      }
    });
  }

  /**
   * We need to update the active slide index and let the carousel know
   * we are going backwords. This will trigger an animation when updated.
   */
  function handleGoToPreviousSlide() {
    if (controlsAreLocked) return;

    setSlide(({ activeSlideIndex }) => {
      handleLockingControls();

      // We can't have an active index of -1, so when it's 0 update it to slides length!
      if (activeSlideIndex === 0) {
        return { activeSlideIndex: numberOfSlides - 1, direction: "backwards" };
      } else {
        return {
          activeSlideIndex: activeSlideIndex - 1,
          direction: "backwards",
        };
      }
    });
  }

  /**
   * In order to create the carousel animation effect we need to apply custom styles
   * to the previous, visible, and next slides. In order to apply custom styles we need
   * to know the index of the previous, active, and next slides.
   *
   * This function will return the indexes of each:
   * previous index  0
   * active index    1
   * next index      2
   */
  function getPreviousVisibleAndNextSlideIndex(activeSlideOffset) {
    const visibleSlide =
      activeSlideOffset > numberOfSlides - 1
        ? activeSlideOffset - numberOfSlides
        : activeSlideOffset;

    const previousSlide =
      visibleSlide === 0 ? numberOfSlides - 1 : visibleSlide - 1;

    const nextSlide =
      visibleSlide === numberOfSlides - 1 ? 0 : visibleSlide + 1;

    return {
      previousSlide,
      visibleSlide,
      nextSlide,
    };
  }

  function handleSlideClick(offset) {
    switch (offset) {
      case 0:
        setShowVideos(true);
        break;
      case 1:
        handleGoToPreviousSlide();
        break;
      case 2:
        handleGoToNextSlide();
        break;
      default:
        break;
    }
  }

  /**
   * This is where the magic happens!
   * Depending on which slide index is passed into this function we will return a
   * different set of styles. These styles are what create the transitions.
   */
  function getSlideStylesBasedOnOrdering(
    { previousSlide, visibleSlide, nextSlide },
    index,
  ) {
    // forwrdas and backwards have different transition stlyes
    const isForwards = direction === "forwards";

    // If no indexes match, pass back empty styles
    let styles = {};

    const previousStyles = {
      transform: "translateX(-50%)",
      zIndex: 0,
      visibility: "visible",
    };

    const visibleStyles = {
      transform: "translateX(0)",
      zIndex: isForwards ? 10 : 20,
      visibility: "visible",
    };

    const nextStyles = {
      transform: "translateX(100%)",
      zIndex: isForwards ? 20 : 30,
      visibility: isForwards ? "hidden" : "visible",
    };

    if (index === previousSlide) {
      styles = previousStyles;
    }

    if (index === visibleSlide) {
      styles = visibleStyles;
    }

    if (index === nextSlide) {
      styles = nextStyles;
    }

    return styles;
  }

  return (
    <>
      <Swipeable
        onSwipedRight={handleGoToPreviousSlide}
        onSwipedLeft={handleGoToNextSlide}
      >
        <CarouselContainer ref={ref}>
          <Spacer />
          <TextContainer>
            <AnimateOnChange direction={direction} key={activeSlideIndex}>
              <PullQuote
                dangerouslySetInnerHTML={{
                  __html: `“${activeSlide.pullquote.pullquote}”`,
                }}
              />
              <Name>{activeSlide.name}</Name>
              <Title>{activeSlide.title}</Title>
            </AnimateOnChange>
          </TextContainer>
          <Slides>
            {numberOfSlideSections.map((_, offset) => {
              const activeSlideWithOffset = activeSlideIndex + offset;
              const offsetIndexes = getPreviousVisibleAndNextSlideIndex(
                activeSlideWithOffset,
              );

              return (
                <Fragment key={offset}>
                  <Slide
                    index={offset}
                    key={offset}
                    onClick={() => handleSlideClick(offset)}
                  >
                    {slides.map((_, index) => {
                      const styles = getSlideStylesBasedOnOrdering(
                        offsetIndexes,
                        index,
                      );

                      return (
                        <ImageWrapper
                          key={slides[index].name}
                          style={styles}
                          direction={direction}
                        >
                          <GatsbyImage
                            image={getImage(slides[index].verticalImage)}
                            alt={`${slides[index].name} - ${slides[index].title}`}
                            style={{
                              position: "absolute",
                              left: 0,
                              top: 0,
                              width: "100%",
                              height: "100%",
                              userSelect: "none",
                              userDrag: "none",
                              pointerEvents: "none",
                              touchCallout: "none",
                            }}
                          />
                        </ImageWrapper>
                      );
                    })}
                  </Slide>
                </Fragment>
              );
            })}

            <PlayButton
              onClick={() => handleSlideClick(0)}
              key={activeSlideIndex}
              aria-label="Play"
            >
              <PlayButtonIcon />
              <AnimateOnChange direction={direction} key={activeSlideIndex}>
                <Name name={activeSlide.name}>{activeSlide.name}</Name>
                <Title title={activeSlide.title}>{activeSlide.title}</Title>
              </AnimateOnChange>
            </PlayButton>
            <Controls>
              <ControlButton
                index={"0"}
                onClick={handleGoToPreviousSlide}
                aria-label={intl.t("general.previous", "Previous")}
              >
                <Icons.ArrowRoundedLeft />
              </ControlButton>
              <ControlButton
                index={"1"}
                onClick={handleGoToNextSlide}
                aria-label={intl.t("general.next", "Next")}
              >
                <Icons.ArrowRoundedRight />
              </ControlButton>
            </Controls>
          </Slides>
          <MobileControlsButton onClick={handleGoToNextSlide} />
        </CarouselContainer>
      </Swipeable>

      {showVideos && (
        <TestimonialsCarouselVideos
          setShowVideos={setShowVideos}
          slides={slides}
          activeSlideIndex={activeSlideIndex}
          handleGoToPreviousSlide={handleGoToPreviousSlide}
          handleGoToNextSlide={handleGoToNextSlide}
        />
      )}
    </>
  );
}

export default TestimonialsCarousel;
