import FocusTrap from "focus-trap-react";
import { Component } from "react";
import styled, { keyframes } from "styled-components";
import Swipeable from "./Swipeable";

// Services
import intl from "../services/intl";

// Utils
import keyCodes from "../utils/keyCode";
import { lockScroll } from "../utils/lockScroll";
import { Icons } from "../utils/react-svg";
import { Color, Opacity, responsive } from "../utils/style";

const TRANSITION_DURATION = 550;

const fadeIn = keyframes`
  from {opacity: 0}
  to {opacity: 1}
`;

const Overlay = styled.div`
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2147483647;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  background-color: rgba(0, 0, 0, ${Opacity.light});
  opacity: 0;
  transition: opacity 500ms ease-in-out;
  animation: ${fadeIn} 300ms forwards;
`;

const Container = styled.div.attrs({
  role: "dialog",
})`
  position: relative;
  display: flex;

  width: 280px;
  height: 482px;
  max-height: 482px;

  ${responsive.sm`
    width: 460px;
    height: 389px;
    max-height: 389px;
  `};

  ${responsive.md`
    width: 614px;
    height: 429px;
    max-height: 429px;
  `};

  ${responsive.lg`
    width: 770px;
    height: 425px;
    max-height: 425px;
  `};
`;

export const Slide = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  transition: transform ${TRANSITION_DURATION}ms cubic-bezier(0.42, 0, 0.335, 1);

  &:focus {
    outline: none;
  }

  @media (max-height: 415px) and (orientation: landscape) {
    max-height: calc(100vh - 99px);
  }
`;

const SlideContent = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  background-color: white;
`;

const ControlsContainer = styled.div`
  position: absolute;
  bottom: -52px;
  left: 0;
  right: 0;
  height: 42px;

  display: flex;
  justify-content: center;
  align-items: flex-end;

  &.hideControls {
    display: flex;

    [data-whatintent="mouse"] &.hideControls,
    [data-whatintent="touch"] &.hideControls {
      display: none;
    }
  }
`;

const Counter = styled.div`
  color: ${Color.white};

  p {
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 1px;
    line-height: 26px;
    text-transform: uppercase;
    margin: 0;
  }
`;

// exporting for testing purposes
export const ControlButton = styled.button`
  display: inline-flex;
  align-items: center;

  background: transparent;
  border: none;

  width: 58px;
  height: 28px;

  &:first-child {
    justify-content: flex-start;
  }

  &:last-child {
    justify-content: flex-end;
  }

  [data-whatintent="mouse"] &:focus,
  [data-whatintent="touch"] &:focus {
    outline: none;
  }

  svg {
    width: 18px;
    height: 12px;
    transition: transform 0.2s;

    path {
      stroke: ${Color.white} !important;
    }
  }

  &:hover {
    svg {
      transform: scale(1.1666);
    }
  }
`;

export const CloseButton = styled.button`
  position: absolute;
  top: 16px;
  right: 16px;

  ${responsive.sm`
    right: 24px;
  `}

  background: transparent;
  border: none;
  width: 16px;
  height: 16px;
  padding: 0;

  display: flex;
  justify-content: flex-end;
  z-index: 100;

  [data-whatintent="mouse"] &:focus,
  [data-whatintent="touch"] &:focus {
    outline: none;
  }
`;

export default class Carousel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      initialSlideIndex: props.activeSlideIndex,
      activeSlideIndex: props.activeSlideIndex,
    };

    this.overlayRef = null;
    this.offsets = [];
    this.previousOffsets = [];
    this.handleKeyDown = this.handleKeyDown.bind(this);
  }

  componentDidMount() {
    lockScroll(true, this.overlayRef);
    window.addEventListener("keydown", this.handleKeyDown);
  }

  componentWillUnmount() {
    lockScroll(false, this.overlayRef);
    window.removeEventListener("keydown", this.handleKeyDown);
  }

  handleKeyDown(e) {
    const { repeat } = this.props;
    const keyCode = e.keyCode;

    switch (keyCode) {
      case keyCodes.ESC:
        return this.props.close();
      case keyCodes.LEFT:
        return this.handleGoToPreviousSlide(repeat);
      case keyCodes.RIGHT:
        return this.handleGoToNextSlide(repeat);
      default:
        return;
    }
  }

  handleGoToNextSlide(repeat = false) {
    const { activeSlideIndex } = this.state;

    // If we're repeating and we've reached the end, move back to the first
    // index. Otherwise remain at the current index.
    const indexAtEnd = repeat ? 0 : this.props.slides.length - 1;
    const nextIndex =
      activeSlideIndex === this.props.slides.length - 1
        ? indexAtEnd
        : activeSlideIndex + 1;

    if (nextIndex === activeSlideIndex) return;

    this.setState({
      activeSlideIndex: nextIndex,
    });
  }

  handleGoToPreviousSlide(repeat = false) {
    const { activeSlideIndex } = this.state;

    // If we're repeating and we've reached the beginning, move forward to the
    // last index. Otherwise remain at the current index.
    const indexAtStart = repeat ? this.props.slides.length - 1 : 0;
    const prevIndex =
      activeSlideIndex === 0 ? indexAtStart : activeSlideIndex - 1;

    if (prevIndex === activeSlideIndex) return;

    this.setState({
      activeSlideIndex: prevIndex,
    });
  }

  handleClickSlideCard(index) {
    const { activeSlideIndex } = this.state;
    const { repeat } = this.props;

    // active slide was clicked
    if (index === activeSlideIndex) return;

    const clickedLast = index === this.props.slides.length - 1;
    const clickedFirst = index === 0;
    const forwardFromLast =
      activeSlideIndex === this.props.slides.length - 1 && clickedFirst;
    const backFromBeginning = activeSlideIndex === 0 && clickedLast;

    // check if carousel repeats or not
    if (!repeat) {
      index === activeSlideIndex + 1 || forwardFromLast
        ? this.handleGoToNextSlide()
        : this.handleGoToPreviousSlide();
    } else {
      if (index === activeSlideIndex + 1 || forwardFromLast) {
        this.handleGoToNextSlide(repeat);
      } else if (index === activeSlideIndex - 1 || backFromBeginning) {
        this.handleGoToPreviousSlide(repeat);
      }
    }
  }

  render() {
    let { slides, close, repeat, controls = true } = this.props;
    const { activeSlideIndex } = this.state;

    this.previousOffsets = this.offsets;
    this.offsets = generateTransformOffsets(slides, activeSlideIndex, repeat);

    return (
      <Swipeable
        onSwipedRight={() => this.handleGoToPreviousSlide(repeat)}
        onSwipedLeft={() => this.handleGoToNextSlide(repeat)}
      >
        <Overlay
          ref={(r) => {
            this.overlayRef = r;
          }}
          onClick={() => close()}
        >
          <Container onClick={(e) => e.stopPropagation()}>
            {slides.map((slide, index) => {
              const activeSlide = this.offsets[index] === 0;

              const transform = this.offsets[index];
              const scale = activeSlide ? 1 : 0.9;

              const isOffScreen =
                this.previousOffsets.length &&
                Math.abs(this.previousOffsets[index]) > 100 &&
                Math.abs(this.offsets[index]) > 100;

              const opacity = isOffScreen ? 0 : 1;

              const styles = {
                transform: `translateX(${transform}%) scale(${scale})`,
                opacity,
              };

              return (
                <CarouselSlide
                  key={index}
                  slide={slide}
                  styles={{
                    ...styles,
                    cursor: index === activeSlideIndex ? "auto" : "pointer",
                  }}
                  onClick={() => this.handleClickSlideCard(index)}
                  id={slide.id}
                  index={index}
                  activeSlideIndex={activeSlideIndex}
                  close={() => close()}
                >
                  {index === activeSlideIndex && (
                    <ControlsContainer className={!controls && "hideControls"}>
                      <ControlButton
                        onClick={() => this.handleGoToPreviousSlide(repeat)}
                        aria-label={intl.t("general.previous", "Previous")}
                      >
                        <Icons.ArrowRoundedLeft />
                      </ControlButton>
                      <Counter>
                        <p>
                          {activeSlideIndex + 1} OF {slides.length}
                        </p>
                      </Counter>
                      <ControlButton
                        onClick={() => this.handleGoToNextSlide(repeat)}
                        aria-label={intl.t("general.next", "Next")}
                      >
                        <Icons.ArrowRoundedRight />
                      </ControlButton>
                    </ControlsContainer>
                  )}
                </CarouselSlide>
              );
            })}
          </Container>
        </Overlay>
      </Swipeable>
    );
  }
}

const CarouselSlide = ({
  slide,
  styles,
  close,
  onClick,
  children,
  activeSlideIndex,
  index,
}) => {
  return (
    <FocusTrap
      active={activeSlideIndex === index}
      focusTrapOptions={{
        clickOutsideDeactivates: true,
      }}
    >
      <div>
        <Slide style={styles} onClick={onClick}>
          <SlideContent>
            <CloseButton
              aria-label={intl.t("general.close", "Close")}
              onClick={() => close()}
            >
              <Icons.Close />
            </CloseButton>
            {slide}
          </SlideContent>
        </Slide>
        {children}
      </div>
    </FocusTrap>
  );
};

function generateTransformOffsets(slides, activeSlideIndex, repeat = false) {
  // If the slides are not repeating, we simply tranform the slides relative
  // to the active slide index.
  if (!repeat) {
    const offsets = slides.map((_, index) => {
      return (index - activeSlideIndex) * 100;
    });
    return offsets;
  }

  // If we want the slides to repeat, generate a different set of transforms.
  const offsets = slides.map((_, index) => {
    if (slides.length / 2 < index) {
      return (index - slides.length) * 100 * -1;
    } else {
      return index * 100 * -1;
    }
  });

  let newOffsets = [];

  for (let i = 0; i < offsets.length; i++) {
    let pointer =
      (Math.abs(i - offsets.length) + activeSlideIndex) % offsets.length;
    newOffsets.push(offsets[pointer]);
  }
  return newOffsets;
}
